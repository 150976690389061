.el-checkbox[data-v-09f5bf63] {
  width: 160px !important;
  margin-right: 20px;
  margin-bottom: 8px;
}
.el-checkbox[data-v-09f5bf63]:nth-child(4n-1) {
  margin-right: 0;
}
.el-checkbox + .el-checkbox[data-v-09f5bf63] {
  margin-left: 0;
}
.xingxing[data-v-09f5bf63] {
  position: relative;
  background: #fff6f7;
  height: 28px;
  vertical-align: top;
  line-height: 28px;
  text-indent: 1em;
  border: 1px solid #c2c2c2;
  width: 353px;
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.xingxing .savepassi[data-v-09f5bf63] {
  position: absolute;
  right: 20px;
  top: 2px;
  font-size: 16px;
  width: 16px;
}
.xingxing i[data-v-09f5bf63] {
  margin-top: 3px;
  color: #fe6c6f;
}
.top_select[data-v-09f5bf63] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}